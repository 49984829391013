<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3
							b Funnels - {{this.form.type}} new entity
			el-row.text-left(:gutter="12")
				h5 Funnel Basic Details
				p You can assign a name to the funnel, however, remember that the name that you choose will have a part in building the link to the funnel. You cannot choose an existing name of a funnel for the same brand as the later will override the older one.&nbsp;
					| You can assign to a funnel family (organization unit), add a note or add some comments.

			el-form(:form="form")
				el-row(:gutter="12")
					el-col(:xs="24", :sm="12" :md="4")
						el-form-item(label="Link Name" v-model="form.name" :error="errors.name.s ? errors.name.m : ''" v-bind:class="{'is-invalid': errors.name.s}")
							el-input(v-model="form.name" placeholder="Name" )

					el-col(:xs="24" :sm="12" :md="4")
						el-form-item(label="Note" v-model="form.note" :error="errors.note.s ? errors.note.m : ''" v-bind:class="{'is-invalid': errors.note.s}")
							el-radio-group.w-100(v-model="form.note")
								el-input(v-model="form.note" placeholder="Note")

					el-col(:xs="24" :sm="4")
						el-form-item(label="Funnel Family" :error="errors.funnel_family_id.s ? errors.funnel_family_id.m : ''" v-bind:class="{'is-invalid': errors.funnel_family_id.s}")
							el-select.w-100(filterable="", v-model="form.funnel_family_id")
								el-option(label="Select Funnel Family" value="")
								el-option(v-for="o of funnelFamiliesOptions" :label="o.t" :value="o.v")
					el-col(:xs="24", :sm="12" :md="4")
						el-form-item(v-if="form.type==='edit'" label="UUID")
							el-input(:value="form.uuid" placeholder="Name" readonly="" )

					el-col(:span="24")
						el-form-item(label="Comments" prop="comments")
							el-input(type="textarea" :rows="5" v-model="form.comments" placeholder="Lorem Ipsum Dolor Sit Amet" :maxlength="3000" show-word-limit="")

				el-row.mt-3(:gutter="12")
					h5.text-left
						b Flags
				el-row.mt-3(:gutter="12")
					el-col(:xs="24", :sm="4")
						el-form-item(label="SMS" label-position="top")
							el-switch.w-100(v-model="form.sms" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="4")
						el-form-item(label="Password Include" label-position="top")
							el-switch.w-100(v-model="form.password" active-text="On" inactive-text="Off")
							
					el-col(:xs="24", :sm="4")
						el-form-item(label="Allow Duplicates" label-position="top")
							el-switch.w-100(v-model="form.allow_duplicates" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="4")
						el-form-item(label="Is Strict Phone Number" label-position="top")
							el-switch.w-100(v-model="form.is_strict_phone" active-text="On" inactive-text="Off")

					//el-col(:xs="24", :sm="4")
						el-form-item(label="Redirect Params" label-position="top")
							el-switch.w-100(v-model="form.allow_redirect_params_reg" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="4")
						el-form-item(label="Direction RTL/LTR" label-position="top")
							el-switch.w-100(v-model="form.rtl" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="4")
						el-form-item(label="Hide E-Mail Field" label-position="top")
							el-switch.w-100(v-model="form.no_email_field" active-text="On" inactive-text="Off")



				el-row.text-left.mt-3
					h5
						b Vertical / Horizontal
				el-row(:gutter="24")
					el-col(:xs="24", :sm="4")
						el-form-item(label="Step \"Pre\"" label-position="top")
							el-switch.w-100(v-model="form.horizontal_form_pre" active-text="Horizontal" inactive-text="Vertical" inactive-color="#13ce66")

					el-col(:xs="24", :sm="4")
						el-form-item(label="Step One" label-position="top")
							el-switch.w-100(v-model="form.horizontal_form_step1" active-text="Horizontal" inactive-text="Vertical" inactive-color="#13ce66")

				el-divider
				el-row.mt-4(:gutter="12")
					el-col(:xs="24" :sm="8")
						el-form-item(label="Step Pre URL: " label-position="top")
							label.chooseable(v-if="form.brand_id !== ''" @click="previewForm(`${iframe_path}/step-pre/${(brandOptions.filter(e => e.v===form.brand_id))[0].t}/${form.name}`)") {{`${iframe_path}/step-pre/${(brandOptions.filter(e => e.v===form.brand_id))[0].t}/${form.name}`}}
								font-awesome-icon.icon.alt.big-icon.ml-3(v-b-tooltip.html.hover-right="", title="Click to preview", size="lg", :icon="['fa', 'search']")
					el-col(:xs="24" :sm="8")
						el-form-item(label="Step 1 URL: " label-position="top")
							label.chooseable(v-if="form.brand_id !== ''" @click="previewForm(`${iframe_path}/step-1/${(brandOptions.filter(e => e.v===form.brand_id))[0].t}/${form.name}`)") {{`${iframe_path}/step-1/${(brandOptions.filter(e => e.v===form.brand_id))[0].t}/${form.name}`}}
								font-awesome-icon.icon.alt.big-icon.ml-3(v-b-tooltip.html.hover-right="", title="Click to preview", size="lg", :icon="['fa', 'search']")
					el-col(:xs="24" :sm="8")
						el-form-item(label="Step 2 URL: " label-position="top")
							label.chooseable(v-if="form.brand_id !== ''" @click="previewForm(`${iframe_path}/step-2/${(brandOptions.filter(e => e.v===form.brand_id))[0].t}/${form.name}`)") {{`${iframe_path}/step-2/${(brandOptions.filter(e => e.v===form.brand_id))[0].t}/${form.name}`}}
								font-awesome-icon.icon.alt.big-icon.ml-3(v-b-tooltip.html.hover-right="", title="Click to preview", size="lg", :icon="['fa', 'search']")
				el-divider
				h4.text-left
					b Redirects
					el-tooltip(content="Generate Links" )
						font-awesome-icon.icon.alt.big-icon.ml-3.chooseable(size="lg", :icon="['fa', 'link']", @click="generateLinks")
				el-row.mt-3()
					el-col(:span="24")
						el-form-item.text-left()
							template(slot="label")
								label Redirect To Same Domain?&nbsp;
								small.text-success (Will redirect to the iFrame's ancestor host for <b>step 1</b>)
							el-radio-group(v-model="form.ancestor_priority_s1" :fill="form.ancestor_priority_s1 === true ? '#409EFF' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
				el-row(:gutter="12")
					el-col(:xs="24", :sm="12" :md="12")
						el-form-item(label="Redirect Step 1" :error="errors.redirect_step1.s ? errors.redirect_step1.m : ''" v-bind:class="{'is-invalid': errors.redirect_step1.s}")
							el-input(v-if="!form.ancestor_priority_s1" v-model="form.redirect_step1" placeholder="URL to step 1")
							el-tooltip(v-if="form.ancestor_priority_s1" content='Change "Redirect to same domain" to "false" to activate this field')
								el-input(v-model="form.redirect_step1" placeholder="URL to step 1" :disabled="form.ancestor_priority_s1")
					el-col(:xs="24", :sm="12" :md="12")
						el-form-item(label="Ancestor Path Step1"	:error="errors.ancestor_path_s1.s ? errors.ancestor_path_s1.m : ''" v-bind:class="{'is-invalid': errors.ancestor_path_s1.s}")
							el-input(v-if="form.ancestor_priority_s1" v-model="form.ancestor_path_s1" placeholder="URL to step 1")
							el-tooltip(v-if="!form.ancestor_priority_s1" content='Change "Redirect to same domain" to "true" to activate this field')
								el-input(v-model="form.ancestor_path_s1" placeholder="URL to step 1"	:disabled="!form.ancestor_priority_s1")

				el-row.mt-4()
					el-col(:span="24")
						el-form-item.text-left()
							template(slot="label")
								label Redirect To Same Domain?&nbsp;
								small.text-success (Will redirect to the iFrame's ancestor host for <b>step 2</b>)
							el-radio-group(v-model="form.ancestor_priority_s2" :fill="form.ancestor_priority_s2 === true ? '#409EFF' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
				el-row(:gutter="12")
					el-col(:xs="24", :sm="12" :md="12")
						el-form-item(label="Redirect Step 2" :error="errors.redirect_step2.s ? errors.redirect_step2.m : ''" v-bind:class="{'is-invalid': errors.redirect_step2.s}")
							el-input(v-if="!form.ancestor_priority_s2" v-model="form.redirect_step2" placeholder="URL to step 2")
							el-tooltip(v-if="form.ancestor_priority_s2" content='Change "Redirect to same domain" to "false" to activate this field')
								el-input(v-model="form.redirect_step2" placeholder="URL to step 2" :disabled="form.ancestor_priority_s2")

					el-col(:xs="24", :sm="12" :md="12")
						el-form-item(label="Ancestor Path Step 2"	:error="errors.ancestor_path_s2.s ? errors.ancestor_path_s2.m : ''" v-bind:class="{'is-invalid': errors.ancestor_path_s2.s}")
							el-input(v-if="form.ancestor_priority_s2" v-model="form.ancestor_path_s2" placeholder="URL to step 2")
							el-tooltip(v-if="!form.ancestor_priority_s2" content='Change "Redirect to same domain" to "true" to activate this field')
								el-input(v-model="form.ancestor_path_s2" placeholder="URL to step 2"	:disabled="!form.ancestor_priority_s2")

				el-row.mt-4()
					el-col(:span="24")
						el-form-item.text-left()
							template(slot="label")
								label Redirect To Same Domain?&nbsp;
								small.text-success (Will redirect to the iFrame's ancestor host for <b>Thankyou Page</b>)
							el-radio-group(v-model="form.ancestor_priority_app" :fill="form.ancestor_priority_app === 1 ? '#409EFF' : form.ancestor_priority_app === 2 ? 'orange' : 'red'" @change="s3RadioHandler")
								el-radio-button(:label="1") On
								el-radio-button(:label="2") Default Autologin
								el-radio-button(:label="0") Off

				el-row(:gutter="12")
					el-col(:xs="24", :sm="8" :md="8")
						el-form-item(label="Redirect App (Panel)"	:error="errors.redirect_app.s ? errors.redirect_app.m : ''" v-bind:class="{'is-invalid': errors.redirect_app.s}")
							el-input(v-if="form.ancestor_priority_app === 0" v-model="form.redirect_app" placeholder="URL to panel")
							el-tooltip(v-if="form.ancestor_priority_app === 1 || form.ancestor_priority_app === 2" content='Change "Redirect to same domain" to "off" to activate this field')
								el-input(v-model="form.redirect_app" placeholder="URL to panel"	:disabled="form.ancestor_priority_app === 1 || form.ancestor_priority_app === 2 ")

					el-col(:xs="24", :sm="8" :md="8")
						el-form-item(label="Crawler Autologin"	:error="errors.autologin_default_path.s ? errors.autologin_default_path.m : ''" v-bind:class="{'is-invalid': errors.autologin_default_path.s}")
							el-select.w-100(v-if="form.ancestor_priority_app === 2" v-model="form.autologin_default_path" placeholder="Autologin Path" filterable="")
								el-option(label="- Select Redirect URL -" value="")
								el-option(v-for="o of iframeDomainsOptions" :label="o.t" :value="o.v")
							el-tooltip(v-if="form.ancestor_priority_app === 0 || form.ancestor_priority_app === 1" content='Change "Redirect to same domain" to "default autologin" to activate this field')
								el-input(v-model="form.autologin_default_path" placeholder="Autologin Path"	 :disabled="form.ancestor_priority_app === 0 || form.ancestor_priority_app === 1")


					el-col(:xs="24", :sm="8" :md="8")
						el-form-item(label="Ancestor Path App (Panel)"	:error="errors.ancestor_path_app.s ? errors.ancestor_path_app.m : ''" v-bind:class="{'is-invalid': errors.ancestor_path_app.s}")
							el-input(v-if="form.ancestor_priority_app === 1" v-model="form.ancestor_path_app" placeholder="URL to panel")
							el-tooltip(v-if="form.ancestor_priority_app === 0 || form.ancestor_priority_app === 2" content='Change "Redirect to same domain" to "on" to activate this field')
								el-input(v-model="form.ancestor_path_app" placeholder="URL to panel"	 :disabled="form.ancestor_priority_app === 0 || form.ancestor_priority_app === 2")

				el-divider
				h4.text-left
					b Configurations
				el-row.mt-3(:gutter="12")

					el-col(:xs="24", :sm="6" :md="4")
						el-form-item(label="Language" :error="errors.language.s ? errors.language.m : ''" v-bind:class="{'is-invalid': errors.language.s}")
							el-select.w-100(filterable="", v-model="form.language")
								el-option(value="" label="- Select language -"	:disabled="true")
								el-option(v-for="o of langOptions" :value="o.v" :label="o.t")
					el-col(:xs="24", :sm="6" :md="4")
						el-form-item(label="Brand" :error="errors.brand_id.s ? errors.brand_id.m : ''" v-bind:class="{'is-invalid': errors.brand_id.s}")
							el-select.w-100(filterable="", v-model="form.brand_id")
								el-option(value="" label="- Select Brand -"	:disabled="true")
								el-option(v-for="o of brandOptions" :value="o.v" :label="o.t")
					el-col(:xs="24", :sm="6" :md="4")
						el-form-item(label="Integrations" :error="errors.integration_id.s ? errors.integration_id.m : ''" v-bind:class="{'is-invalid': errors.integration_id.s}")
							el-select.w-100(filterable="", v-model="form.integration_id" :disabled="!form.brand_id" )
								el-option(value="" label="- Select Integration -" :disabled="true")
								el-option(v-for="o of integrationOptions" v-if="form.brand_id === o.b" :value="o.v" :label="o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="24", :sm="4")
						el-form-item(label="Custom Fix" label-position="top")
							el-select.w-100(v-model="form.custom_fix_id")
								el-option(:value="null" label="- Select Custom Fix -")
								el-option(v-for="o of customFixOptions" :label="o.t" :value="o.v")
				h5.text-left
					b Flow
				el-row.mt-3(:gutter="12")
					el-col(:xs="11", :sm="6")
						el-form-item(label="Fallback Integrations 1 (Flow)" :error="errors.fallback_integration_id.s ? errors.fallback_integration_id.m : ''" v-bind:class="{'is-invalid': errors.fallback_integration_id.s}")
							el-select.w-100(filterable="", v-model="form.fallback_integration_id" :disabled="!form.brand_id" )
								el-option(value="" label="- Select Integration -" :disabled="true")
								el-option(v-for="o of integrationOptions" :value="o.v" :label="o.bn + ' - ' + o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="11", :sm="6")
						el-form-item(label="Fallback Funnel 1 (Flow)" :error="errors.fallback_funnel_id.s ? errors.fallback_funnel_id.m : ''" v-bind:class="{'is-invalid': errors.fallback_funnel_id.s}")
							el-select.w-100(filterable="", v-model="form.fallback_funnel_id" :disabled="!form.fallback_integration_id" )
								el-option(value="" label="- Select Funnel -" :disabled="true")
								el-option(v-if="o.i === form.fallback_integration_id" v-for="o of funnelOptions" :value="o.v" :label="o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="2", :sm="1")
						el-form-item()
							template(slot="label") &nbsp;
							el-tooltip(content="Clear the flow entry")
								el-button(size="midi" icon="el-icon-delete" type="danger" @click="clearEntry(['fallback_integration_id', 'fallback_funnel_id'])" :disabled="form.fallback_integration_id === '' || !form.fallback_integration_id")
				el-row.mt-3(:gutter="12")
					el-col(:xs="12", :sm="6")
						el-form-item(label="Fallback Integrations 2 (Flow)" :error="errors.fallback_integration_2_id.s ? errors.fallback_integration_2_id.m : ''" v-bind:class="{'is-invalid': errors.fallback_integration_2_id.s}")
							el-select.w-100(filterable="", v-model="form.fallback_integration_2_id" :disabled="!form.brand_id" )
								el-option(value="" label="- Select Integration -" :disabled="true")
								el-option(v-for="o of integrationOptions"	:value="o.v" :label="o.bn + ' - ' + o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="12", :sm="6")
						el-form-item(label="Fallback Funnel 2 (Flow)" :error="errors.fallback_funnel_2_id.s ? errors.fallback_funnel_2_id.m : ''" v-bind:class="{'is-invalid': errors.fallback_funnel_2_id.s}")
							el-select.w-100(filterable="", v-model="form.fallback_funnel_2_id" :disabled="!form.fallback_integration_2_id" )
								el-option(value="" label="- Select Funnel -" :disabled="true")
								el-option(v-if="o.i === form.fallback_integration_2_id" v-for="o of funnelOptions"	:value="o.v" :label="o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="2", :sm="1")
						el-form-item()
							template(slot="label") &nbsp;
							el-tooltip(content="Clear the flow entry")
								el-button(size="midi" icon="el-icon-delete" type="danger" @click="clearEntry(['fallback_integration_2_id', 'fallback_funnel_2_id'])" :disabled="form.fallback_integration_2_id === '' || !form.fallback_integration_2_id")
				el-row.mt-3(:gutter="12")
					el-col(:xs="12", :sm="6")
						el-form-item(label="Fallback Integrations 3 (Flow)" :error="errors.fallback_integration_3_id.s ? errors.fallback_integration_3_id.m : ''" v-bind:class="{'is-invalid': errors.fallback_integration_3_id.s}")
							el-select.w-100(filterable="", v-model="form.fallback_integration_3_id" :disabled="!form.brand_id" )
								el-option(value="" label="- Select Integration -" :disabled="true")
								el-option(v-for="o of integrationOptions"	:value="o.v" :label="o.bn + ' - ' + o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="12", :sm="6")
						el-form-item(label="Fallback Funnel 3 (Flow)" :error="errors.fallback_funnel_3_id.s ? errors.fallback_funnel_3_id.m : ''" v-bind:class="{'is-invalid': errors.fallback_funnel_3_id.s}")
							el-select.w-100(filterable="", v-model="form.fallback_funnel_3_id" :disabled="!form.fallback_integration_3_id" )
								el-option(value="" label="- Select Funnel -" :disabled="true")
								el-option(v-if="o.i === form.fallback_integration_3_id" v-for="o of funnelOptions"	:value="o.v" :label="o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="2", :sm="1")
						el-form-item()
							template(slot="label") &nbsp;
							el-tooltip(content="Clear the flow entry")
								el-button(size="midi" icon="el-icon-delete" type="danger" @click="clearEntry(['fallback_integration_3_id', 'fallback_funnel_3_id'])" :disabled="form.fallback_integration_3_id === '' || !form.fallback_integration_3_id")

				el-row.mt-3(:gutter="12")
					el-col(:xs="12", :sm="6")
						el-form-item(label="Fallback Integrations 4 (Flow)" :error="errors.fallback_integration_4_id.s ? errors.fallback_integration_4_id.m : ''" v-bind:class="{'is-invalid': errors.fallback_integration_4_id.s}")
							el-select.w-100(filterable="", v-model="form.fallback_integration_4_id" :disabled="!form.brand_id" )
								el-option(value="" label="- Select Integration -" :disabled="true")
								el-option(v-for="o of integrationOptions"	:value="o.v" :label="o.bn + ' - ' + o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="12", :sm="6")
						el-form-item(label="Fallback Funnel 4 (Flow)" :error="errors.fallback_funnel_4_id.s ? errors.fallback_funnel_3_id.m : ''" v-bind:class="{'is-invalid': errors.fallback_funnel_4_id.s}")
							el-select.w-100(filterable="", v-model="form.fallback_funnel_4_id" :disabled="!form.fallback_integration_4_id" )
								el-option(value="" label="- Select Funnel -" :disabled="true")
								el-option(v-if="o.i === form.fallback_integration_4_id" v-for="o of funnelOptions"	:value="o.v" :label="o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="2", :sm="1")
						el-form-item()
							template(slot="label") &nbsp;
							el-tooltip(content="Clear the flow entry")
								el-button(size="midi" icon="el-icon-delete" type="danger" @click="clearEntry(['fallback_integration_4_id', 'fallback_funnel_4_id'])" :disabled="form.fallback_integration_3_id === '' || !form.fallback_integration_4_id")

				el-row.mt-3(:gutter="12")
					el-col(:xs="12", :sm="6")
						el-form-item(label="Fallback Integrations 5 (Flow)" :error="errors.fallback_integration_3_id.s ? errors.fallback_integration_5_id.m : ''" v-bind:class="{'is-invalid': errors.fallback_integration_5_id.s}")
							el-select.w-100(filterable="", v-model="form.fallback_integration_5_id" :disabled="!form.brand_id" )
								el-option(value="" label="- Select Integration -" :disabled="true")
								el-option(v-for="o of integrationOptions"	:value="o.v" :label="o.bn + ' - ' + o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="12", :sm="6")
						el-form-item(label="Fallback Funnel 5 (Flow)" :error="errors.fallback_funnel_5_id.s ? errors.fallback_funnel_5_id.m : ''" v-bind:class="{'is-invalid': errors.fallback_funnel_5_id.s}")
							el-select.w-100(filterable="", v-model="form.fallback_funnel_5_id" :disabled="!form.fallback_integration_5_id" )
								el-option(value="" label="- Select Funnel -" :disabled="true")
								el-option(v-if="o.i === form.fallback_integration_5_id" v-for="o of funnelOptions"	:value="o.v" :label="o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="2", :sm="1")
						el-form-item()
							template(slot="label") &nbsp;
							el-tooltip(content="Clear the flow entry")
								el-button(size="midi" icon="el-icon-delete" type="danger" @click="clearEntry(['fallback_integration_5_id', 'fallback_funnel_5_id'])" :disabled="form.fallback_integration_5_id === '' || !form.fallback_integration_5_id")

				el-row.mt-3(:gutter="12")
					el-col(:xs="12", :sm="6")
						el-form-item(label="Fallback Integrations 6 (Flow)" :error="errors.fallback_integration_6_id.s ? errors.fallback_integration_6_id.m : ''" v-bind:class="{'is-invalid': errors.fallback_integration_6_id.s}")
							el-select.w-100(filterable="", v-model="form.fallback_integration_6_id" :disabled="!form.brand_id" )
								el-option(value="" label="- Select Integration -" :disabled="true")
								el-option(v-for="o of integrationOptions"	:value="o.v" :label="o.bn + ' - ' + o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="12", :sm="6")
						el-form-item(label="Fallback Funnel 6 (Flow)" :error="errors.fallback_funnel_6_id.s ? errors.fallback_funnel_6_id.m : ''" v-bind:class="{'is-invalid': errors.fallback_funnel_6_id.s}")
							el-select.w-100(filterable="", v-model="form.fallback_funnel_6_id" :disabled="!form.fallback_integration_6_id" )
								el-option(value="" label="- Select Funnel -" :disabled="true")
								el-option(v-if="o.i === form.fallback_integration_6_id" v-for="o of funnelOptions"	:value="o.v" :label="o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="2", :sm="1")
						el-form-item()
							template(slot="label") &nbsp;
							el-tooltip(content="Clear the flow entry")
								el-button(size="midi" icon="el-icon-delete" type="danger" @click="clearEntry(['fallback_integration_6_id', 'fallback_funnel_6_id'])" :disabled="form.fallback_integration_6_id === '' || !form.fallback_integration_6_id")


				h5.text-left
					b Style
				el-row.mt-3(:gutter="12")
					el-col(:xs="24", :sm="6")
						el-form-item(label="Button Color" :error="errors.button_color.s ? errors.button_color.m : ''" v-bind:class="{'is-invalid': errors.button_color.s}")
							el-select.w-100(filterable="", v-model="form.button_color")
								el-option(value="" label="- Select Color Schema -" :disabled="true")
								el-option(v-for="o of buttonColorOptions" :value="o.v" :label="o.t")

					el-col(:xs="24", :sm="6")
						el-form-item(label="CSS-PRE" :error="errors.css_pre.s ? errors.css_pre.m : ''" v-bind:class="{'is-invalid': errors.css_pre.s}")
							//el-input(v-model="form.css" placeholder="Path to relevant CSS")
							el-select.w-100(filterable="", v-model="form.css_pre")
								el-option(value="" label="- Select relevant CSS -" :disabled="true")
								el-option(v-for="o of styleOptions" :value="o.v" :label="o.t")

					el-col(:xs="24", :sm="6")
						el-form-item(label="CSS - Step 1" :error="errors.css_s1.s ? errors.css_s1.m : ''" v-bind:class="{'is-invalid': errors.css_s1.s}")
							//el-input(v-model="form.css" placeholder="Path to relevant CSS")
							el-select.w-100(filterable="", v-model="form.css_s1")
								el-option(value="" label="- Select relevant CSS -" :disabled="true")
								el-option(v-for="o of styleOptions" :value="o.v" :label="o.t")

					el-col(:xs="24", :sm="6")
						el-form-item(label="CSS - Step 2" :error="errors.css_s2.s ? errors.css_s2.m : ''" v-bind:class="{'is-invalid': errors.css_s2.s}")
							//el-input(v-model="form.css" placeholder="Path to relevant CSS")
							el-select.w-100(filterable="", v-model="form.css_s2")
								el-option(value="" label="- Select relevant CSS -" :disabled="true")
								el-option(v-for="o of styleOptions" :value="o.v" :label="o.t")
					el-col(:xs="24", :sm="6")
						el-form-item(label="Fonts" :error="errors.fonts.s ? errors.fonts.m : ''" v-bind:class="{'is-invalid': errors.fonts.s}")
							el-input(v-model="form.fonts" placeholder="Path to fonts CSS")

				h5.text-left
					b Redirect Tracking Params

				p.text-left Add or remove params that you want to be redirected between the pages. For example&nbsp;
					span.text-success.text-bold gclid,&nbsp;
					span.text-success.text-bold fbcid,&nbsp;
					span.text-success.text-bold vkclid,&nbsp;
					span.text-success.text-bold yclid,&nbsp;
					| etc. This param, will be concatenated in the URI in case it exists.&nbsp;
					span(style="font-style: underline") Relevant to the 2nd step.
				el-row(:gutter="12")
					el-col(:xs="24")
						el-form-item(label="Tracking params: " label-position="top")
							.w-100.text-left.d-block
								el-tag(:key='tag', v-for='tag in form.params_to_redirect', closable='', :disable-transitions='false', @close='handleClosePTR(tag)')
									| {{tag}}
								el-input.input-new-tag(v-if='inputVisiblePTR', v-model='inputValuePTR', ref='saveTagInputPTR', size='mini', @keyup.enter.native='handleInputConfirmPTR', @blur='handleInputConfirmPTR')
								el-button.button-new-tag(v-else='', size='small', @click='showInputPTR') + New Tag
								el-button.button-new-tag(type="primary" @click="populateDefaultPTR") Lead Details
								el-button.button-new-tag(type="danger" @click="clearPTR") Clear All

				h5.text-left
					b Override Traffic Source Params
				p.text-left You can toggle on and off mapping the traffic source param. You must toggle on in order to map the traffic source id to the internal one. You must also set the&nbsp;
					| key that the application will listen to and the key in the request payload that will be sent to the brands. <b style="color:red">@todo add database</b>
				el-row(:gutter="12")
					el-col(:xs="12", :sm="6")
						el-form-item(label="Incoming Param" label-position="top")
							el-tooltip(v-if="form.override_tracking_params === 'OFF' || form.override_tracking_params === 'DATABASE'" content='Change the mapping of traffic source param to "incoming"')
								el-input(v-model="form.incoming_raw_ts_param" placeholder="Incoming Param" :disabled="form.override_tracking_params === 'OFF' || form.override_tracking_params === 'DATABASE'")
							el-input(v-else="" v-model="form.incoming_raw_ts_param" placeholder="Incoming Param" :disabled="form.override_tracking_params === 'OFF' || form.override_tracking_params === 'DATABASE'")
					el-col(:xs="12", :sm="6")
						el-form-item(label="Param in payload" label-position="top")
							el-tooltip(v-if="form.override_tracking_params === 'OFF' " content='Change the mapping of traffic source param to either "incoming" or "database"')
								el-input(v-model="form.outgoing_mapped_ts_param" placeholder="Param in payload" :disabled="form.override_tracking_params === 'OFF'")
							el-input(v-if="form.override_tracking_params !== 'OFF'"	v-model="form.outgoing_mapped_ts_param" placeholder="Param in payload" :disabled="form.override_tracking_params === 'OFF'")
					el-col(:xs="24", :sm="6")
						el-form-item(label="Toggle Mapping Type" label-position="top")
							el-radio-group.w-100(v-model="form.override_tracking_params"	:fill="form.override_tracking_params === 'INCOMING' ? '#409EFF' : form.override_tracking_params === 'DATABASE' ? 'orange' : 'red'")
								el-radio-button(label="INCOMING") Incoming
								el-radio-button(label="DATABASE") Database
								el-radio-button(label="OFF") Off

				h5.text-left
					b iFrame Post Messages
				p.text-left Toggle on or off the iFrame post messages. This will allow the ancestor window to send messages to the iFrame.
					el-form-item( label="iFrame Post Messages" label-position="top")
						el-switch(v-model="form.support_post_messages" active-text="On" inactive-text="Off")

				el-row(:gutter="24" v-if="form.support_post_messages" )
					el-col(:span="24")
						table
							td.w-50

								p.text-left.el-form-item__content(style="margin-bottom: 22px")
									label.el-form-item__label.d-inline(style="") FBP (Facebook Pixel)
									el-switch.text-left.w-100.d-inline(v-model="form.fbp_forward_toggle" active-text="On" inactive-text="Off")
							td.w-100
								p.text-left.ml-4
									el-form-item(label="Ancestor Origins" label-position="left")
										.w-100.text-left.d-block
											el-tag(:key='tag', v-for='tag in form.iframe_host_origin_list', closable='', :disable-transitions='false', @close='handleCloseOrigin(tag)')
												| {{tag}}
											el-input.input-new-tag(v-if='inputVisibleOrigin', v-model='inputValueOrigin', ref='saveTagInputOrigin', size='mini', @keyup.enter.native='handleInputConfirmOrigin', @blur='handleInputConfirmOrigin')
											el-button.button-new-tag(v-else='', size='small', @click='showInputOrigin') + New Tag

				el-divider
				h4.text-left
					b Crawler
				p.text-left Settings to control the crawler process. The aim of the crawler is to simulate a user's journey through the redirect link that is received from the brand or to a specific page.&nbsp;
					| The crawler uses an actual headless browser with a predefined agent.
				el-row(:gutter="12")
					el-col(:xs="24" :sm="12" :lg="6")
						el-form-item(label="Crawler Strategy")
							el-radio-group.w-100.text-left(v-model="form.init_headless_redirect" :fill="form.init_headless_redirect === 'META' ? '#409EFF' : form.init_headless_redirect === 'CUSTOM' ? 'orange' : 'red'")
								el-radio-button(:label="'META'") From Meta
								el-radio-button(:label="'CUSTOM'") From Custom
								el-radio-button(:label="'NONE'") None
					el-col(:xs="24" :sm="12" :lg="6" v-if="form.init_headless_redirect === 'CUSTOM'")
						el-form-item(label="Wait for the selector" )
							el-switch.w-100(v-model="form.redirect_url_expect_selector" active-text="On" inactive-text="Off")
				el-row(:gutter="12")
					el-col(:xs="24", :sm="9" :xl="6" v-if="form.init_headless_redirect === 'CUSTOM'")
						el-form-item(label="Custom Crawler URL")
							el-input(v-model="form.custom_headless_redirect_url" placeholder="Custom Redirect URL")
					el-col(:xs="24", :sm="9" :xl="6" v-if="form.init_headless_redirect === 'CUSTOM' && form.redirect_url_expect_selector")
						el-form-item(label="Selector to wait for")
							el-input(v-model="form.redirect_url_selector_string" placeholder="X-Path or CSS Selector")
					el-col(:xs="24"  :xl="4" :sm="6")
						el-form-item()
							template(slot="label") Sleep duration&nbsp;
								small.text-success (in milliseconds)
							el-input-number.w-100(v-model="form.crawler_sleep_duration" :min="500" :max="600000" :step="500" :precision="0" controls-position="right" placeholder="Sleep Duration")

				el-divider
				h4.text-left
					b Just Click (Mailer)
				el-row.mt-3(:gutter="12")
					el-col(:xs="24", :sm="6")
						el-form-item(label="JustClick Success (LEGACY)" :error="errors.jc_success.s ? errors.jc_success.m : ''" v-bind:class="{'is-invalid': errors.jc_success.s}")
							el-input(v-model="form.jc_success" placeholder="Justclick Successful")
					el-col(:xs="24", :sm="6")
						el-form-item(label="JustClick Failed (LEGACY)" :error="errors.jc_failed.s ? errors.jc_failed.m : ''" v-bind:class="{'is-invalid': errors.jc_failed.s}")
							el-input(v-model="form.jc_failed" placeholder="JustClick Failed")

					el-col(:xs="24", :sm="6")
						el-form-item(label="JustClick Pre" :error="errors.jc_pre.s ? errors.jc_pre.m : ''" v-bind:class="{'is-invalid': errors.jc_pre.s}")
							el-input(v-model="form.jc_pre" placeholder="JustClick Pre")

					el-col(:xs="24", :sm="6")
						el-form-item(label="JustClick Step One" :error="errors.jc_step_1.s ? errors.jc_step_1.m : ''" v-bind:class="{'is-invalid': errors.jc_step_1.s}")
							el-input(v-model="form.jc_step_1" placeholder="JustClick Step One")

					el-col(:xs="24", :sm="6")
						el-form-item(label="JustClick Step Two" :error="errors.jc_step_2.s ? errors.jc_step_2.m : ''" v-bind:class="{'is-invalid': errors.jc_step_2.s}")
							el-input(v-model="form.jc_step_2" placeholder="JustClick Step Two")

					el-col(:xs="24", :sm="6")
						el-form-item(label="JustClick User" :error="errors.jc_user.s ? errors.jc_user.m : ''" v-bind:class="{'is-invalid': errors.jc_user.s}")
							el-select.w-100(v-model="form.jc_user" placeholder="JustClick User" )
								el-option(v-for="o of jcUserOptions" :value="o.v" :label="o.t")

				el-divider
				h4.text-left
					b Telegram
				el-row(:gutter="12")
					el-col(:span="24")
						el-form-item(label="Telegram Notification Channel" label-position="top")
							.w-100.text-left.d-block
								el-tag(:key='tag', v-for='tag in form.tg_channel_id', closable='', :disable-transitions='false', @close='handleClose(tag)')
									| {{tag}}
								el-input.input-new-tag(v-if='inputVisible', v-model='inputValue', ref='saveTagInput', size='mini', @keyup.enter.native='handleInputConfirm', @blur='handleInputConfirm')
								el-button.button-new-tag(v-else='', size='small', @click='showInput') + New Tag

				el-divider
				h4.text-left
					b Translations
					font-awesome-icon.icon.alt.big-icon.ml-3.chooseable(size="lg", :icon="['fa', 'download']", @click="loadTranslates")
				el-row(:gutter="12")
					el-col(:xs="24", :sm="6")
						el-form-item(label="Full Name" :error="errors.t_full_name.s ? errors.t_full_name.m : ''" v-bind:class="{'is-invalid': errors.t_full_name.s}")
							el-input(v-model="form.translation.full_name" placeholder="Full Name")

					el-col(:xs="24", :sm="6")
						el-form-item(label="First Name" :error="errors.t_first_name.s ? errors.t_first_name.m : ''" v-bind:class="{'is-invalid': errors.t_first_name.s}")
							el-input(v-model="form.translation.first_name" placeholder="first_name")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Last Name" :error="errors.t_last_name.s ? errors.t_last_name.m : ''" v-bind:class="{'is-invalid': errors.t_last_name.s}")
							el-input(v-model="form.translation.last_name" placeholder="last_name")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Email" :error="errors.t_email.s ? errors.t_email.m : ''" v-bind:class="{'is-invalid': errors.t_email.s}")
							el-input(v-model="form.translation.email" placeholder="email")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Password" :error="errors.t_password.s ? errors.t_password.m : ''" v-bind:class="{'is-invalid': errors.t_password.s}")
							el-input(v-model="form.translation.password" placeholder="password")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Password Wrong Format" :error="errors.t_password_format.s ? errors.t_password_format.m : ''" v-bind:class="{'is-invalid': errors.t_password_format.s}")
							el-input(v-model="form.translation.password_format" placeholder="password_format")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Submit Button Regular Text" :error="errors.t_submit_btn.s ? errors.t_submit_btn.m : ''" v-bind:class="{'is-invalid': errors.t_submit_btn.s}")
							el-input(v-model="form.translation.submit_btn" placeholder="submit_btn")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Submit Button Bold Text" :error="errors.t_submit_btn_strong.s ? errors.t_submit_btn_strong.m : ''" v-bind:class="{'is-invalid': errors.t_submit_btn_strong.s}")
							el-input(v-model="form.translation.submit_btn_strong" placeholder="submit_btn_strong")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Confirm" :error="errors.t_submit_confirm.s ? errors.t_submit_confirm.m : ''" v-bind:class="{'is-invalid': errors.t_submit_confirm.s}")
							el-input(v-model="form.translation.submit_confirm" placeholder="submit_confirm")

					el-col(:xs="24", :sm="6")
						el-form-item(label="OTP (SMS)" :error="errors.t_otp.s ? errors.t_otp.m : ''" v-bind:class="{'is-invalid': errors.t_otp.s}")
							el-input(v-model="form.translation.otp" placeholder="otp")

					el-col(:xs="24", :sm="6")
						el-form-item(label="OTP Received" :error="errors.otp_received.s ? errors.otp_received.m : ''" v-bind:class="{'is-invalid': errors.otp_received.s}")
							el-input(v-model="form.translation.otp_received" placeholder="otp_received")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Generate Password" :error="errors.generate.s ? errors.generate.m : ''" v-bind:class="{'is-invalid': errors.generate.s}")
							el-input(v-model="form.translation.generate" placeholder="generate")
				el-divider
				h4.text-left
					b Translations - Errors
				el-row(:gutter="12")
					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: API" :error="errors.e_api.s ? errors.e_api.m : ''" v-bind:class="{'is-invalid': errors.e_api.s}")
							el-input(v-model="form.translation.e_api" placeholder="Error: e_api")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: Full Name" :error="errors.e_full_name.s ? errors.e_full_name.m : ''" v-bind:class="{'is-invalid': errors.e_full_name.s}")
							el-input(v-model="form.translation.e_full_name" placeholder="Error: e_full_name")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: Short Full Name" :error="errors.s_full_name.s ? errors.s_full_name.m : ''" v-bind:class="{'is-invalid': errors.s_full_name.s}")
							el-input(v-model="form.translation.s_full_name" placeholder="Error: s_full_name")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: First Name" :error="errors.e_first_name.s ? errors.e_first_name.m : ''" v-bind:class="{'is-invalid': errors.e_first_name.s}")
							el-input(v-model="form.translation.e_first_name" placeholder="Error: e_first_name")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: Last Name" :error="errors.e_last_name.s ? errors.e_last_name.m : ''" v-bind:class="{'is-invalid': errors.e_last_name.s}")
							el-input(v-model="form.translation.e_last_name" placeholder="Error: e_last_name")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: E-Mail Empty" :error="errors.e_email.s ? errors.e_email.m : ''" v-bind:class="{'is-invalid': errors.e_email.s}")
							el-input(v-model="form.translation.e_email" placeholder="Error: e_email")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: E-Mail Format" :error="errors.f_email.s ? errors.f_email.m : ''" v-bind:class="{'is-invalid': errors.f_email.s}")
							el-input(v-model="form.translation.f_email" placeholder="Error: f_email")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: Phone Empty" :error="errors.e_phone.s ? errors.e_phone.m : ''" v-bind:class="{'is-invalid': errors.e_phone.s}")
							el-input(v-model="form.translation.e_phone" placeholder="Error: e_phone")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: Phone Format" :error="errors.f_phone.s ? errors.f_phone.m : ''" v-bind:class="{'is-invalid': errors.f_phone.s}")
							el-input(v-model="form.translation.f_phone" placeholder="Error: f_phone")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: Password Empty" :error="errors.e_password.s ? errors.e_password.m : ''" v-bind:class="{'is-invalid': errors.e_password.s}")
							el-input(v-model="form.translation.e_password" placeholder="Error: e_password")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: Password Format" :error="errors.f_password.s ? errors.f_password.m : ''" v-bind:class="{'is-invalid': errors.f_password.s}")
							el-input(v-model="form.translation.f_password" placeholder="Error: f_password")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: Password Short" :error="errors.s_password.s ? errors.s_password.m : ''" v-bind:class="{'is-invalid': errors.s_password.s}")
							el-input(v-model="form.translation.s_password" placeholder="Error: s_password")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: No SMS OTP" :error="errors.sms.s ? errors.sms.m : ''" v-bind:class="{'is-invalid': errors.sms.s}")
							el-input(v-model="form.translation.sms" placeholder="Error: sms")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: Account Exists" :error="errors.account_exists.s ? errors.account_exists.m : ''" v-bind:class="{'is-invalid': errors.account_exists.s}")
							el-input(v-model="form.translation.account_exists" placeholder="Error: account_exists")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Error: Phone Format" :error="errors.type_phone.s ? errors.type_phone.m : ''" v-bind:class="{'is-invalid': errors.type_phone.s}")
							el-input(v-model="form.translation.type_phone" placeholder="Error: type_phone")

				el-divider
				h4.text-left
					b Override Parameters
				p.text-left If set, these parameters will override the parameters that were set in "integration" at "misc" section for this funnel.
				p.text-left.text-success
					i Note: You can add dynamic properties to the request payload by adding a prefix "#key_" to a param name, so, the property without the prefix will be added to the payload as a MISC param.
				el-row(:gutter="12")
					el-col(:span="24")
						el-form-item(label="Override Parameters")
							el-button(type="info" icon="el-icon-plus" @click="addRow('override_params')") Add
						el-row.mt-1.mb-1(v-for="(o, i) of form.override_params" :gutter="6")
							el-col(:span="8")
								el-input(v-model="o.key" placeholder="Key")
							el-col(:span="15")
								el-input(v-model="o.value" placeholder="Value")
							el-col(:span="1")
								el-button(icon="el-icon-minus" type="danger" @click="() => form.override_params.splice(i,1)")


				el-divider
				h4.text-left
					b URI Query Params To Payload Fields Mapping
				p.text-left The following params would be caught from the URI. For example - the landing page was loaded with&nbsp;
					span.text-danger clickId=qqqwwweee&nbsp;
					| , but, you want to send the broker to a field "custom1" - You must choose the key and map to the foreign field.

				el-row(:gutter="12")
					el-col(:span="24")
						el-form-item(label="Fields Mapping")
							el-button(type="info" icon="el-icon-plus" @click="addRow('fields_mapping')") Add
						el-row.mt-1.mb-1(v-for="(o, i) of form.fields_mapping" :gutter="6")
							el-col(:span="8")
								el-input(v-model="o.key" placeholder="Query Param")
							el-col(:span="15")
								el-input(v-model="o.value" placeholder="Foreign Field")
							el-col(:span="1")
								el-button(icon="el-icon-minus" type="danger" @click="() => form.fields_mapping.splice(i,1)")


				el-divider
				h4.text-left
					b External Post-Back list&nbsp;&nbsp;
					small.text-danger() (*Under Construction)
				p.text-left You can add here a list of FTDs to acknowledge. You must set the URL and the payload
				el-row(:gutter="24")
					el-col(:xs="24")
						el-form-item(label="URL, payload params")
							el-button(type="info" icon="el-icon-plus" @click="addPostback()") Add
						el-row.mt-1.mb-1(v-for="(o, i) of form.postbacks" :gutter="6")
							el-col(:xs="6" :sm="2")
								label.label-grey(v-if="i===0") Request Method
								el-select(v-model="o.method" placeholder="Method")
									el-option(value="GET" label="GET")
									el-option(value="POST" label="POST")
							el-col(:xs="24" :sm="9")
								label.label-grey(v-if="i===0") URL address where to send
								el-input(v-model="o.url" placeholder="URL Address")
							el-col(:xs="12" :sm="3")
								label.label-grey(v-if="i===0") Click ID Override
								el-input(v-model="o.click_param" placeholder="Value")
							el-col(:xs="12" :sm="3")
								label.label-grey(v-if="i===0") CID override
								el-input(v-model="o.cust_param" placeholder="Value")
							el-col(:xs="20" :sm="5")
								label.label-grey(v-if="i===0") Additional params (JSON)
								el-input(v-model="o.additional_params" placeholder="Value")
							el-col(:xs="4" :span="1")
								label.label-grey(v-if="i===0") &nbsp;
								el-button(icon="el-icon-minus" type="danger" @click="() => form.postbacks.splice(i,1)")

				el-divider
				el-row(:gutter="12" justify="between")
					el-button(@click="submit(true)" type="primary" v-b-tooltip.html.hover-right="", title="Save and redirect back") Save
					el-button(@click="submit(false)" type="info" v-b-tooltip.html.hover-right="", title="Only save (ideal to preview the funnel's iFrmae)") Apply
					el-button(type="danger" @click="$router.push({name: 'iframe-list'})" v-b-tooltip.html.hover-right="", title="Go Back") Cancel

		el-dialog(title='Preview', :visible.sync='showPreviewDialog', width='80%', center='')
			el-row(type="flex" justify="center" :gutter="24" )
				el-col(:span="24")
					iframe.iframe-box(:src="currentPath")
			el-row( :gutter="24" )
				el-col(:span="24")
					.d-flex.justify-content-center
						el-button(type='danger', @click='showPreviewDialog = false') close

</template>


<style lang="scss">
	.iframe-box {
		width: 100%;
		height: 50vh;
		border: none;
	}

	.label-grey {
		float: left;
		font-size: 14px;
		color: #606266
	}

	.el-tag + .el-tag {
		margin-left: 10px;
	}
	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}
	.input-new-tag {
		width: 120px;
		margin-left: 10px;
		vertical-align: bottom;
	}
</style>


<script lang="js">
	import Vue from 'vue';
	import validator from 'validator';
	// import * as translations from '../../../struct/translates'

	let clearBeforeSend = (data) => {
		let body = {...data};
		delete body.type;
		delete body.uuid;
		if (!body.tg_channel_id || body.tg_channel_id === '') body.tg_channel_id = null;
		if (!body.fallback_integration_id || body.fallback_integration_id === '') body.fallback_integration_id = null;
		if (!body.fallback_integration_2_id || body.fallback_integration_2_id === '') body.fallback_integration_2_id = null;
		if (!body.fallback_integration_3_id || body.fallback_integration_3_id === '') body.fallback_integration_3_id = null;
		if (!body.fallback_integration_4_id || body.fallback_integration_4_id === '') body.fallback_integration_4_id = null;
		if (!body.fallback_integration_5_id || body.fallback_integration_5_id === '') body.fallback_integration_5_id = null;
		if (!body.fallback_integration_6_id || body.fallback_integration_6_id === '') body.fallback_integration_6_id = null;
		if (!body.fallback_funnel_id || body.fallback_funnel_id === '') body.fallback_funnel_id = null;
		if (!body.fallback_funnel_2_id || body.fallback_funnel_2_id === '') body.fallback_funnel_2_id = null;
		if (!body.fallback_funnel_3_id || body.fallback_funnel_3_id === '') body.fallback_funnel_3_id = null;
		if (!body.fallback_funnel_4_id || body.fallback_funnel_4_id === '') body.fallback_funnel_4_id = null;
		if (!body.fallback_funnel_5_id || body.fallback_funnel_5_id === '') body.fallback_funnel_5_id = null;
		if (!body.fallback_funnel_6_id || body.fallback_funnel_6_id === '') body.fallback_funnel_6_id = null;
		let hash = {};
		body.override_params.forEach(e => hash[e.key] = e.value);
		body.override_params = {...hash};
		hash = {};
		body.fields_mapping.forEach(e => hash[e.key] = e.value);
		body.fields_mapping = hash;
		return body;
	};

	const $errors = {
		name: {s: false, m: ''},
		comments: {s: false, m: ''},
		note: {s: false, m: ''},
		css_pre: {s: false, m: ''},
		css_s1: {s: false, m: ''},
		css_s2: {s: false, m: ''},
		fonts: {s: false, m: ''},
		jc_failed: {s: false, m: ''},
		jc_success: {s: false, m: ''},
		jc_pre: {s: false, m: ''},
		jc_step_1: {s: false, m: ''},
		jc_step_2: {s: false, m: ''},
		jc_user: {s: false, m: ''},
		language: {s: false, m: ''},
		redirect_step1: {s: false, m: ''},
		redirect_step2: {s: false, m: ''},
		redirect_app: {s: false, m: ''},
		ancestor_path: {s: false, m: ''},
		ancestor_path_s1: {s: false, m: ''},
		ancestor_path_s2: {s: false, m: ''},
		ancestor_path_app: {s: false, m: ''},
		autologin_default_path: {s: false, m: ''},
		integration_id: {s: false, m: ''},
		fallback_integration_id: {s: false, m: ''},
		fallback_integration_2_id: {s: false, m: ''},
		fallback_integration_3_id: {s: false, m: ''},
		fallback_integration_4_id: {s: false, m: ''},
		fallback_integration_5_id: {s: false, m: ''},
		fallback_integration_6_id: {s: false, m: ''},
		fallback_funnel_id: {s: false, m: ''},
		fallback_funnel_2_id: {s: false, m: ''},
		fallback_funnel_3_id: {s: false, m: ''},
		fallback_funnel_4_id: {s: false, m: ''},
		fallback_funnel_5_id: {s: false, m: ''},
		fallback_funnel_6_id: {s: false, m: ''},
		funnel_family_id: {s: false, m: ''},
		button_color: {s: false, m: ''},
		brand_id: {s: false, m: ''},

		custom_headless_redirect_url: {s: false, m: ''},
		redirect_url_selector_string: {s: false, m: ''},

		// Translations
		t_lang: {s: false, m: ''},
		t_full_name: {s: false, m: ''},
		t_first_name: {s: false, m: ''},
		t_last_name: {s: false, m: ''},
		t_email: {s: false, m: ''},
		t_password: {s: false, m: ''},
		t_password_format: {s: false, m: ''},
		t_submit_btn: {s: false, m: ''},
		t_submit_btn_strong: {s: false, m: ''},
		t_submit_confirm: {s: false, m: ''},
		t_otp: {s: false, m: ''},
		generate: {s: false, m: ''},

		e_api: {s: false, m: ''},
		e_full_name: {s: false, m: ''},
		s_full_name: {s: false, m: ''},
		e_first_name: {s: false, m: ''},
		e_last_name: {s: false, m: ''},
		e_email: {s: false, m: ''},
		f_email: {s: false, m: ''},
		e_phone: {s: false, m: ''},
		f_phone: {s: false, m: ''},
		e_password: {s: false, m: ''},
		f_password: {s: false, m: ''},
		s_password: {s: false, m: ''},
		sms: {s: false, m: ''},
		account_exists: {s: false, m: ''},
		type_phone: {s: false, m: ''},
		otp_received: {s: false, m: ''},
	}

	export default {
		name: 'funnel-form',
		async beforeRouteEnter(to, from, next) {
			if (to.name === 'iframe-add-funnel' && !to.query.id) {
				try {
					let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: 'bf19b80b-341d-4676-a762-5e9e98a01b72,f06ac207-2c06-4c8f-a94d-eaaf0fabf513,3673e785-6d24-40ae-a91b-d7dac9e4c50b,f19ea6af-0f3a-4984-aa88-cff4928c6bf1,95e5744a-9964-42d4-ae26-c3e591ecb7e0,e91556b8-c5da-442f-a0bf-31f2ce567abe,9e832481-2c11-4021-ba13-0dcc5487e3f7,1e0c327a-73cf-4159-b154-ec498ade6dcd,49f06cec-46b0-4237-bf9a-d4d3969e5af6,a9e3d85a-1ef8-4a8a-8eef-50d464c2a1b4,98c8a608-4821-4384-9647-7700148f20ce,1a11188c-a446-4e71-8f39-1751fb5cf74a'})
					next(vm => {
						vm.funnelFamiliesOptions = options.funnel_families;
						vm.integrationOptions = options.integration_extra;
						vm.funnelOptions = options.funnel_extra;
						vm.brandOptions = options.brand;
						vm.langOptions = options.languages;
						vm.styleOptions = options.styles;
						vm.brandOptions = options.brand;
						vm.jcUserOptions = options.jc_users;
						vm.buttonColorOptions = options.button_color;
						vm.customFixOptions = options.custom_fix;
						vm.iframeTranslationOptions = options.iframe_translations;
						vm.iframeDomainsOptions = options.iframe_domains;
						vm.form.params_to_redirect = ["pixel_id","fbclid","gclid","v_label","c_label","c_id"]
					});
				} catch (e) {
					console.error(e);
					Vue.router.push({name: 'brand-list'})
				}
			} else if (to.name === 'iframe-edit-funnel' || (to.name === 'iframe-add-funnel' &&	to.query.id)) {
				try {
					let type = (to.name === 'iframe-add-funnel' && to.query) ? 'query' : 'params';
					let result = await Vue.apix.sendHttpRequest('GET', 'funnel/get', {id: to[type].id});
					let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: 'bf19b80b-341d-4676-a762-5e9e98a01b72,f06ac207-2c06-4c8f-a94d-eaaf0fabf513,3673e785-6d24-40ae-a91b-d7dac9e4c50b,f19ea6af-0f3a-4984-aa88-cff4928c6bf1,95e5744a-9964-42d4-ae26-c3e591ecb7e0,e91556b8-c5da-442f-a0bf-31f2ce567abe,9e832481-2c11-4021-ba13-0dcc5487e3f7,1e0c327a-73cf-4159-b154-ec498ade6dcd,49f06cec-46b0-4237-bf9a-d4d3969e5af6,a9e3d85a-1ef8-4a8a-8eef-50d464c2a1b4,98c8a608-4821-4384-9647-7700148f20ce,1a11188c-a446-4e71-8f39-1751fb5cf74a'})
					next(vm => {
						vm.entity_id = to[type].id;
						vm.funnelFamiliesOptions = options.funnel_families;
						vm.integrationOptions = options.integration_extra;
						vm.funnelOptions = options.funnel_extra;
						vm.brandOptions = options.brand;
						vm.langOptions = options.languages;
						vm.styleOptions = options.styles;
						vm.brandOptions = options.brand;
						vm.jcUserOptions = options.jc_users;
						vm.buttonColorOptions = options.button_color;
						vm.customFixOptions = options.custom_fix;
						vm.iframeTranslationOptions = options.iframe_translations;
						vm.iframeDomainsOptions = options.iframe_domains;
						vm.memory__ancestor_priority_app = result.ancestor_priority_app;
						vm.form = {
							type: (to.name === 'iframe-add-funnel' && to.query) ? 'clone' : 'edit',
							uuid: 											result.uuid,
							override_tracking_params: 	result.override_tracking_params || 'OFF',
							incoming_raw_ts_param: 			result.incoming_raw_ts_param || '',
							outgoing_mapped_ts_param: 	result.outgoing_mapped_ts_param || '',
							params_to_redirect: 				result.params_to_redirect,
							tg_channel_id: 							result.tg_channel_id,
							funnel_family_id: 					result.funnel_family_id,
							name: 											result.name,
							comments: 									result.comments,
							note: 											result.note,
							sms: 												result.sms,
							custom_fix_id: 							result.custom_fix_id,
							button_color: 							result.button_color,
							css_pre: 										result.css_pre,
							css_s1: 										result.css_s1,
							css_s2: 										result.css_s2,
							password: 									result.password,
							fonts: 											result.fonts,
							language: 									result.language,
							jc_failed: 									result.jc_failed,
							jc_success: 								result.jc_success,
							jc_pre: 										result.jc_pre || '',
							jc_step_1: 									result.jc_step_1 || '',
							jc_step_2: 									result.jc_step_2 || '',
							jc_user: 										result.jc_user || '',
							redirect_step1: 						result.redirect_step1 || '',
							redirect_step2: 						result.redirect_step2 || '',
							redirect_app: 							result.redirect_app || '',
							ancestor_priority: 					result.ancestor_priority || false,
							ancestor_priority_s1: 			result.ancestor_priority_s1 || false,
							ancestor_priority_s2: 			result.ancestor_priority_s2 || false,
							ancestor_priority_app: 			result.ancestor_priority_app,
							ancestor_path: 							result.ancestor_path || '',
							ancestor_path_s1: 						result.ancestor_path_s1 || '',
							ancestor_path_s2: 						result.ancestor_path_s2 || '',
							ancestor_path_app: 						result.ancestor_path_app || '',
							autologin_default_path: 		result.autologin_default_path || '',
							integration_id: 						result.integration_id,
							fallback_integration_id: 		result.fallback_integration_id,
							fallback_integration_2_id: 	result.fallback_integration_2_id,
							fallback_integration_3_id: 	result.fallback_integration_3_id,
							fallback_integration_4_id: 	result.fallback_integration_4_id,
							fallback_integration_5_id: 	result.fallback_integration_5_id,
							fallback_integration_6_id: 	result.fallback_integration_6_id,
							fallback_funnel_id: 				result.fallback_funnel_id,
							fallback_funnel_2_id: 			result.fallback_funnel_2_id,
							fallback_funnel_3_id: 			result.fallback_funnel_3_id,
							fallback_funnel_4_id: 			result.fallback_funnel_4_id,
							fallback_funnel_5_id: 			result.fallback_funnel_5_id,
							fallback_funnel_6_id: 			result.fallback_funnel_6_id,
							brand_id: 									result.brand_id,
							translation: 								result.translation,
							allow_duplicates: 					result.allow_duplicates,
							is_strict_phone: 						result.is_strict_phone,
							// allow_redirect_params_reg: 	result.allow_redirect_params_reg,
							no_email_field: 						result.no_email_field,
							rtl: 												result.rtl,
							horizontal_form_pre: 				result.horizontal_form_pre,
							horizontal_form_step1: 			result.horizontal_form_step1,
							postbacks: 									result.postbacks || [],
							support_post_messages:			result.support_post_messages,
							fbp_forward_toggle:					result.fbp_forward_toggle,
							iframe_host_origin_list:		result.iframe_host_origin_list,

							init_headless_redirect:				result.init_headless_redirect,
							custom_headless_redirect_url: result.custom_headless_redirect_url,
							redirect_url_expect_selector: result.redirect_url_expect_selector,
							redirect_url_selector_string: result.redirect_url_selector_string,
							crawler_sleep_duration: 			result.crawler_sleep_duration,

							override_params: [],
							fields_mapping: []
						}

						Object.entries(result.override_params).forEach(e => {
								vm.counter.override_params++;
								vm.form.override_params.push({key: e[0], value: e[1]})
						});
						Object.entries(result.fields_mapping).forEach(e => {
							vm.counter.fields_mapping++;
							vm.form.fields_mapping.push({key: e[0], value: e[1]})
						});
					});
				} catch (e) {
					console.error(e);
					Vue.router.push({name: 'brand-list'})
				}
			}
		},
		data() {
			return {
				dynamicTags: ['test'],
				inputVisible: false,
				inputValue: '',
				inputVisibleOrigin: false,
				inputVisiblePTR: false,
				inputValueOrigin: '',
				inputValuePTR: '',
				showPreviewDialog: false,
				currentPath: '',
				counter: {
					override_params: 0,
					fields_mapping: 0,
					postbacks: 0
				},
				memory__ancestor_priority_app: 0, // remove
				form: {
					uuid: '',
					comments: '',
					override_tracking_params: 'OFF',
					incoming_raw_ts_param: '',
					outgoing_mapped_ts_param: '',
					type: 'add',
					name: '',
					note: '',
					custom_fix_id: null,
					sms: false,
					password: false,
					button_color: 'warning',
					tg_channel_id: [],
					params_to_redirect: [],
					css_pre: '',
					css_s1: '',
					css_s2: '',
					fonts: 'fonts.min.css',
					language: 'en',
					jc_failed: '',
					jc_success: '',
					jc_pre: '',
					jc_step_1: '',
					jc_step_2: '',
					jc_user: 'EN',
					redirect_step1: '',
					redirect_step2: '',
					redirect_app: '',
					ancestor_priority: false, // remove
					ancestor_priority_s1: false, // remove
					ancestor_priority_s2: false, // remove
					ancestor_priority_app: 0, // remove

					ancestor_path: '',
					ancestor_path_s1: '',
					ancestor_path_s2: '',
					ancestor_path_app: '',
					autologin_default_path: '',
					integration_id: '',
					fallback_integration_id: '',
					fallback_integration_2_id: '',
					fallback_integration_3_id: '',
					fallback_integration_4_id: '',
					fallback_integration_5_id: '',
					fallback_integration_6_id: '',
					fallback_funnel_id: '',
					fallback_funnel_2_id: '',
					fallback_funnel_3_id: '',
					fallback_funnel_4_id: '',
					fallback_funnel_5_id: '',
					fallback_funnel_6_id: '',
					funnel_family_id: '',
					brand_id: '',
					allow_duplicates: false,
					is_strict_phone: false,
					// allow_redirect_params_reg: false,
					no_email_field: false,
					rtl: false,
					support_post_messages: false,
					fbp_forward_toggle: false,
					iframe_host_origin_list: [],
					horizontal_form_pre: false,
					horizontal_form_step1: false,

					init_headless_redirect: 'NONE',
					custom_headless_redirect_url: '',
					redirect_url_expect_selector: false,
					redirect_url_selector_string: '',
					crawler_sleep_duration: 500,

					translation: {
						lang: '',
						full_name: '',
						first_name: '',
						last_name: '',
						email: '',
						password: '',
						password_format: '',
						submit_btn: '',
						submit_btn_strong: '',
						submit_confirm: '',
						otp: '',
						generate: '',

						e_api: '',
						e_full_name: '',
						s_full_name: '',
						e_first_name: '',
						e_last_name: '',
						e_email: '',
						f_email: '',
						e_phone: '',
						f_phone: '',
						e_password: '',
						f_password: '',
						s_password: '',
						sms: '',
						account_exists: '',
						type_phone: '',
						otp_received: ''
					},
					override_params: [],
					fields_mapping: [],
					postbacks: []
				},
				errors: JSON.parse(JSON.stringify($errors)),
				brandOptions: [],
				integrationOptions: [],
				funnelOptions: [],
				langOptions: [],
				buttonColorOptions: [],
				styleOptions: [],
				funnelFamiliesOptions: [],
				customFixOptions: [],
				iframeTranslationOptions: [],
				jcUserOptions: [],
				iframeDomainsOptions: []
			}
		},
		computed: {
			iframe_path() {
				return process.env.VUE_APP_IFRAME
			}
		},
		methods: {
			handleClose(tag) {
				this.form.tg_channel_id.splice(this.form.tg_channel_id.indexOf(tag), 1);
			},
			handleClosePTR(tag) {
				this.form.params_to_redirect.splice(this.form.params_to_redirect.indexOf(tag), 1);
			},
			handleCloseOrigin(tag) {
				this.form.iframe_host_origin_list.splice(this.form.iframe_host_origin_list.indexOf(tag), 1);
			},
			showInput() {
				this.inputVisible = true;
				this.$nextTick(_ => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},
				showInputPTR() {
						this.inputVisiblePTR = true;
						this.$nextTick(_ => {
								this.$refs.saveTagInputPTR.$refs.input.focus();
						});
				},
			clearPTR() {
				this.form.params_to_redirect = [];
			},
			populateDefaultPTR() {
				this.form.params_to_redirect = [... new Set([...this.form.params_to_redirect,  'first_name','last_name','email','phone',])];
			},
			showInputOrigin() {
						this.inputVisibleOrigin = true;
						this.$nextTick(_ => {
								this.$refs.saveTagInputOrigin.$refs.input.focus();
						});
				},

			handleInputConfirm() {
				let inputValue = this.inputValue;
				if(!inputValue || inputValue === '') {
					this.inputVisible = false;
					this.inputValue = '';
				} else if (inputValue && inputValue.length > 10 && inputValue.length < 15) {
					this.form.tg_channel_id.push(inputValue);
					this.inputVisible = false;
					this.inputValue = '';
				} else {
					this.$notify.error({title: 'Validation Error', message: 'Validation failed. Telegram channel must consist of either 11 or 14 characters'})
				}
			},
			handleInputConfirmPTR() {
				let inputValuePTR = this.inputValuePTR;
				if(!inputValuePTR || inputValuePTR === '') {
					this.inputVisiblePTR = false;
					this.inputValuePTR = '';
				} else if (inputValuePTR && inputValuePTR.length > 1 && inputValuePTR.length < 16) {
					this.form.params_to_redirect.push(inputValuePTR);
					this.inputVisiblePTR = false;
					this.inputValuePTR = '';
				} else {
					this.$notify.error({title: 'Validation Error', message: 'Validation failed. Redirect param must be more then 1 character and less then 16 characters'})
				}
			},
			handleInputConfirmOrigin() {
				let inputValueOrigin = this.inputValueOrigin;
				if(!inputValueOrigin || inputValueOrigin === '') {
					this.inputVisibleOrigin = false;
					this.inputValueOrigin = '';
				} else if (inputValueOrigin && inputValueOrigin.length > 1 && (validator.isURL(inputValueOrigin) || inputValueOrigin.includes('://localhost')|| inputValueOrigin.includes('://127.0.0.1'))) {
					this.form.iframe_host_origin_list.push(inputValueOrigin);
					this.inputVisibleOrigin = false;
					this.inputValueOrigin = '';
				} else {
					this.$notify.error({title: 'Validation Error', message: 'Validation failed. Redirect param must be more then 1 character and be a valid URL. Got: ' + inputValueOrigin})
				}
			},
			s3RadioHandler($event) {
				console.log('[s3RadioHandler]', $event)
				if($event === 2) {
					this.$confirm('Are you sure you want to change the value? It will change the crawler mode to META as well', 'Warning', {
						confirmButtonText: 'OK',
						cancelButtonText: 'Cancel',
						type: 'warning'
					}).then(() => {
						this.form.ancestor_priority_app = $event;
						this.memory__ancestor_priority_app = $event;
						this.form.init_headless_redirect = 'META';
						this.$notify.warning({title: 'Attention!', message: 'Crawler mode changed to META as well!'})
					}).catch(() => {
						this.form.ancestor_priority_app = this.memory__ancestor_priority_app;
					});
				} else {
					this.memory__ancestor_priority_app = $event;
				}
			},
			generateLinks() {
				if(!this.form.brand_id) return this.$notify.warning({title: 'Validation Error', message: 'Brand is not selected!'});
				if(!this.form.name) return this.$notify.warning({title: 'Validation Error', message: 'Name is not set!'});
				this.form.redirect_step1 = `${this.iframe_path}/step-1/${(this.brandOptions.filter(e => e.v===this.form.brand_id))[0].t}/${this.form.name}`
				this.form.redirect_step2 = `${this.iframe_path}/step-2/${(this.brandOptions.filter(e => e.v===this.form.brand_id))[0].t}/${this.form.name}`
				this.form.redirect_app = `${this.iframe_path}/api/v1/redirect`
			},
			previewForm(path) {
				this.showPreviewDialog = !this.showPreviewDialog;
				this.currentPath = path;
			},
			loadTranslates() {

				this.form.translation = {...this.iframeTranslationOptions[this.form.language]}
			},
			addRow(type) {
				this.form[type].push({key: `key_${this.counter[type]}`, value: `value_${this.counter[type]}`});
				this.counter[type]++;
			},
			addPostback() {
				this.form.postbacks.push({ url: 'https://example.com', method: 'GET', click_param: '', cust_param: '', additional_params: '' })
			},
			clearEntry(arr) {
				arr.forEach(e => this.form[e] = null);
			},
			async submit(redirect = false) {

				if (!this.validate()) return this.$notify.warning({ title: 'Validation error', message: 'Please, fix the validation errors in this form to proceed. You can see the error text in red font'});
				let loader = this.$loading.show();
				try {
					if (this.form.type === 'add' || this.form.type === 'clone') {
						let result = await this.$apix.sendHttpRequest('POST', 'funnel/create', {data: clearBeforeSend(this.form)})
						if (result.id) this.$router.push({name: 'iframe-list'})
						loader.hide()
					} else {
						let result = await this.$apix.sendHttpRequest('PUT', `funnel/${'update/' + this.entity_id}`, {data: clearBeforeSend(this.form)})
						if (redirect) {
							loader.hide();
							this.$router.push({name: 'iframe-list'})
						} else setTimeout(() => loader.hide(), 7000)
					}
				} catch (e) {
					loader.hide();
					console.error(e);
				}
			},
			validate() {
				this.errors = JSON.parse(JSON.stringify($errors));
				let error = false;

				if (this.form.brand_id === '') {
					this.errors.brand_id = {s: true, m: 'Brand is mandatory'};
					error = true;
				}

				if (this.form.integration_id === '') {
					this.errors.integration_id = {s: true, m: 'integration_id is mandatory'};
					error = true;
				}

				// if (this.form.jc_failed === '') {
				//		 this.errors.jc_failed = {s: true, m: 'jc_failed is mandatory'};
				//		 error = true;
				// }
				//
				// if (this.form.jc_success === '') {
				//		 this.errors.jc_success = {s: true, m: 'jc_success is mandatory'};
				//		 error = true;
				// }

				if (this.form.jc_user === '') {
					this.errors.jc_user = {s: true, m: 'You must select the JC account'};
					error = true;
				}

				if (this.form.name === '') {
					this.errors.name = {s: true, m: 'name cannot be empty'};
					error = true;
				} else if (!validator.isAscii(this.form.name)) {
					this.errors.name = {s: true, m: 'name malformed'};
					error = true;
				}

				if (this.form.note === '' || !this.form.note) {
					this.errors.note = {s: true, m: 'note cannot be empty'};
					error = true;
				} else if (!validator.isAscii(this.form.note)) {
					this.errors.note = {s: true, m: 'note malformed'};
					error = true;
				}

				if (this.form.funnel_family_id === '') {
					this.errors.funnel_family_id = {s: true, m: 'funnel family must be selected'};
					error = true;
				} else if (!Number.isInteger(this.form.funnel_family_id)) {
					this.errors.funnel_family_id = {s: true, m: 'funnel family must be an integer'};
					error = true;
				}

					// console.log(this.form.redirect_step1)
					// console.log(this.form.redirect_step1.replace(/\|\||_/g, ''))
					// console.log(this.form.redirect_step1.replace(/\|\||_/g, '').toLowerCase())

				if (!this.form.redirect_step1 === '') {
						this.errors.redirect_step1 = {s: true, m: 'redirect_step1 cannot be empty'};
						error = true;
				}
				// } else if (!validator.isURL(this.form.redirect_step1.replace(/\|\||_/g, '').toLowerCase())) {
				// 	this.errors.redirect_step1 = {s: true, m: 'redirect_step1 malformed'};
				// 	error = true;
				// }

				if (this.form.redirect_step2 === '') {
						this.errors.redirect_step2 = {s: true, m: 'redirect_step2 cannot be empty'};
						error = true;
				}
				// } else if (!validator.isURL(this.form.redirect_step2.replace(/\|\|/g, '').toLowerCase())) {
				// 	this.errors.redirect_step2 = {s: true, m: 'redirect_step2 malformed'};
				// 	error = true;
				// }

				if (this.form.ancestor_priority_app === 0 && this.form.redirect_app === '') {
						this.errors.redirect_app = {s: true, m: 'API URL cannot be empty'};
						error = true;
				}

				if (this.form.ancestor_priority_s1 && this.form.ancestor_path_s1 === '') {
						this.errors.ancestor_path_s1 = {s: true, m: 'Ancestor URL cannot be empty'};
						error = true;
				}

				if (this.form.ancestor_priority_s2 && this.form.ancestor_path_s2 === '') {
						this.errors.ancestor_path_s2 = {s: true, m: 'Ancestor URL cannot be empty'};
						error = true;
				}

				if (this.form.ancestor_priority_app === 1 && this.form.ancestor_path_app === '') {
						this.errors.ancestor_path_app = {s: true, m: 'Ancestor URL cannot be empty'};
						error = true;
				}

				if (this.form.ancestor_priority_app === 2 && this.form.autologin_default_path === '') {
						this.errors.autologin_default_path = {s: true, m: 'Autologin URL must be chosen'};
						error = true;
				}
				// } else if (!validator.isURL(this.form.redirect_app)) {
				// 	this.errors.redirect_app = {s: true, m: 'API URL malformed'};
				// 	error = true;
				// }

				if (this.form.css_pre === '') {
					this.errors.css_pre = {s: true, m: 'CSS-PRE cannot be empty'};
					error = true;
				} else if (!validator.isAscii(this.form.css_pre)) {
					this.errors.css_pre = {s: true, m: 'CSS-PRE must be alphanumeric'};
					error = true;
				}

				if (this.form.css_s1 === '') {
					this.errors.css_s1 = {s: true, m: 'CSS-S1 cannot be empty'};
					error = true;
				} else if (!validator.isAscii(this.form.css_s1)) {
					this.errors.css_s1 = {s: true, m: 'CSS-S1 must be alphanumeric'};
					error = true;
				}

				if (this.form.css_s2 === '') {
					this.errors.css_s2 = {s: true, m: 'CSS-S2 cannot be empty'};
					error = true;
				} else if (!validator.isAscii(this.form.css_s2)) {
					this.errors.css_s2 = {s: true, m: 'CSS-S2 must be alphanumeric'};
					error = true;
				}


				if (this.form.fonts === '') {
					this.errors.fonts = {s: true, m: 'fonts cannot be empty'};
					error = true;
				} else if (!validator.isAscii(this.form.fonts)) {
					this.errors.fonts = {s: true, m: 'fonts must be alphanumeric'};
					error = true;
				}


				// crawler
				if(this.form.init_headless_redirect === 3 || this.form.init_headless_redirect === 2) {
					if (!this.form.custom_headless_redirect_url || this.form.custom_headless_redirect_url === '') {
						this.errors.custom_headless_redirect_url = {s: true, m: 'Custom Headless Redirect URL cannot be empty'};
						error = true;
					} else if (!validator.isURL(this.form.custom_headless_redirect_url)) {
						this.errors.custom_headless_redirect_url = {s: true, m: 'Custom Headless Redirect URL must be a valid URL'};
						error = true;
					}
					if (this.redirect_url_expect_selector && (!this.form.redirect_url_selector_string || this.form.redirect_url_selector_string === '')) {
						this.errors.redirect_url_selector_string = {s: true, m: 'Redirect URL Selector String cannot be empty'};
						error = true;
					}
				}

				return !error;
			}
		}
	}
</script>
